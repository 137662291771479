.MapContainer{
    border-radius: 10px;
    border-style: solid;
    border-color: #b2b2b2;
    border-width: 1px;
    width: 85%;
    user-select: none;
}
.hover-bg-gray:hover{
    fill:gray;
}
@media only screen and (max-width: 767px) {
    .MapContainer{
        width: 100%;
        margin-top: 20px;
    }
}