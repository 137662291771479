.additionalInfoHeader {
  font-size: 15px;
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .AdditonalInfo {
    border-radius: 10px 10px 10px 10px;
    background-color: #e9e9de;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-inline: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .AdditonalInfo {
    border-radius: 10px 10px 10px 10px;
    background-color: #e9e9de;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    height: 60vh;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-inline: 5px;
  }
}
