@media only screen and (min-width: 768px) {

    .USearchBar{
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 3px 6px 0 rgba(0, 0, 0, 0.19);
    }
    .Searchbarfontsize{
        font-size: 15px;
        height: 30px;
    }
    .USearchBar{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-radius: 10px 10px 10px 10px;
        padding: 13px;
        border-style: solid;
        border-width: 0 0 1px 0;
        border-color: #d7d7d7;
    }
    .Searchbarfontsize{
        font-size: 15px;
        height: 30px;
    }
    .SearchButton{
        background-color: #63566e;
    }
}
@media only screen and (max-width: 767px) {
    .USearchBar{
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        align-items: center;
        justify-content: center;
        /*border-radius: 0 0 10px 10px;*/
        border-style: solid;
        border-width: 0 0 1px 0;
        border-color: #d7d7d7;
        padding: 13px;
    }
    .Searchbarfontsize{
        font-size: 15px;
        height: 30px;
    }
    .SearchButton{
        background-color: #63566e;
    }
}