.LogoWrapper{
    display: flex;
    flex: 1;
    height: 100%;
    z-index: 15;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #64596e;
    /*border-radius: 0 0 10px 10px;*/
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}