/*WEB -------------------------------------------------------------------------------------------------------------------------------------------*/
/*WEB -------------------------------------------------------------------------------------------------------------------------------------------*/
/*WEB -------------------------------------------------------------------------------------------------------------------------------------------*/
/*WEB -------------------------------------------------------------------------------------------------------------------------------------------*/
@media only screen and (min-width: 768px) {
  .topContainer {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: center;
    background-color: #ededee;
  }

  /*SearchBar<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
  .searchBar {
    display: flex;
    flex: 1.5;
    font-family: Arial, Helvetica, sans-serif;
  }
  .SearchBarLogoWrapper {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    background-color: #424242;
  }
  .SearchBarLogo {
    display: flex;
    flex: 1.7;
    flex-direction: row;
  }
  .SearchBarInput {
    display: flex;
    flex: 8.5;
    justify-content: center;
    align-items: center;
  }
  /*SearchBar>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

  .detailInfoContainer {
    display: flex;
    flex: 8.5;
    flex-direction: row;
    font-family: Arial, Helvetica, sans-serif;
  }

  /*SideBar -----------------------------*/
  .sideBarWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #4b4b4b;
    margin-right: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
    z-index: 13;
  }
  .sideBar {
    display: flex;
    flex: 1.7;
    flex-direction: column;
  }
  .sideBarList {
    flex: 8;
    display: flex;
    z-index: 11;
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*overflow-y: scroll;*/
  }

  .pagination {
    flex: 0.5;
    display: flex;
    background-color: #655b6e;
    z-index: 12;
    align-items: center;
    justify-content: center;
    /*border-radius: 10px 10px 0 0;*/
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.5), 0 0 0 0 rgba(0, 0, 0, 0);
  }
  /*SideBar -----------------------------*/

  /*detailAndMap ------------------------*/
  .detailAndMap {
    display: flex;
    flex: 8.5;
    background-color: #ededee;
    align-items: flex-start;
    justify-content: center;
  }
  .detailAndMapWrapper {
    display: flex;
    width: 93%;
    height: 90%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    align-items: center;
    align-content: center;
  }
  .detail {
    display: flex;
    flex: 5.5;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: 2%;
  }
  .detailPosition {
    display: flex;
    flex: 5.5;
    align-items: center;
    justify-content: center;
    margin: 20px;
    flex-direction: column;
    text-align: center;
    height: 100%;
  }
  .UAdditonalInfo {
    display: flex;
    flex: 5.5;
    align-items: center;
    justify-content: center;
    margin: 20px;
    flex-direction: column;
    text-align: center;
    height: 100%;
    margin-inline: 10px;
  }
  .map {
    display: flex;
    flex: 4;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  /*detailAndMap ------------------------*/
}
/*MOBILE -------------------------------------------------------------------------------------------------------------------------------------------*/
/*MOBILE -------------------------------------------------------------------------------------------------------------------------------------------*/
/*MOBILE -------------------------------------------------------------------------------------------------------------------------------------------*/
/*MOBILE -------------------------------------------------------------------------------------------------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .topContainer {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: center;
  }

  /*SearchBar-----------------------------*/
  .searchBar {
    position: sticky;
    top: 0;
    font-family: Arial, Helvetica, sans-serif;
  }

  .SearchBarLogo {
    display: none;
  }

  .SearchBarInput {
  }

  /*SearchBar-----------------------------*/
  .detailInfoContainer {
    overflow-y: scroll;
    font-family: Arial, Helvetica, sans-serif;
  }

  /*SideBar <<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
  .sideBar {
    height: 50vh;
    width: 100%;
    display: block;
  }

  .sideBarWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .sideBarList {
    height: 85%;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 15%;
    background-color: #655b6e;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /*SideBar >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

  /*detailAndMap ------------------------*/
  .detailAndMap {
    width: 100%;
  }
  .detailAndMapWrapper {
    padding: 15px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .detail {
    width: 100%;
  }

  .UAdditonalInfo {
  }

  .detailPosition {
  }

  .map {
    width: 100%;
  }

  /*detailAndMap ------------------------*/
}
